


























import { Vue, Prop, Component, Watch } from 'vue-property-decorator'
import BigNumber from 'bignumber.js'
import { getLastTrades } from '@/clients/cpinblocks'
import moment from 'moment'

@Component({
	components: { },
})
export default class SpotLastTrades extends Vue {
	@Prop() productCurrency!: string
	@Prop() productType!: string
	@Prop() unitPriceCurrency!: string
	@Prop() unitPriceType!: string
	@Prop() currentPrice!: string | undefined

	private headers = [
		{ align: 'left', sortable: false, text: this.$t('spotLastTrades.header.at'), value: 'at' },
		{ align: 'right', sortable: false, text: this.$t('spotLastTrades.header.unitPrice'), value: 'unitPrice' },
		{ align: 'right', sortable: false, text: this.$t('spotLastTrades.header.quantity'), value: 'volume' },
		{ align: 'right', sortable: false, text: this.$t('spotLastTrades.header.volume'), value: 'total' },
	]
	private items: object[] = []
	private loading = true

	private formatDate (date: Date): string {
		return moment(date)
			.tz(moment.tz.guess())
			.format("YYYY-MM-DD HH:mm:ss")
	}

	async mounted (): Promise<void> {
		await this.refresh()
	}

	async refresh (): Promise<void> {
		this.loading = true
		const lastTrades = await getLastTrades(this.productCurrency, this.productType, this.unitPriceCurrency, this.unitPriceType, 'updatedAt:DESC')
		for (let o of lastTrades) {
			this.items.push({
				at: this.formatDate(new Date(o.at)),
				unitPrice: o.unitPrice,
				volume: o.volume,
				total: new BigNumber(o.unitPrice).multipliedBy(o.volume).toFixed(2, BigNumber.ROUND_DOWN)
			})
		}
		this.loading = false
	}
}
