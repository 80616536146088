






























































































































































































import {
	deleteSpotOrder,
	getAPIConfiguration,
	getSpotOrders, getSpotPrecedence, getSpotPrecedenceCertificate,
	SpotOrder, SpotOrderType,
} from '@/clients/cpinblocks'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Conf } from '@/models/Conf'
import BigNumber from 'bignumber.js'
import Code2FADialog from '@/components/Code2FADialog.vue'
import ModalNotification from '@/components/ModalNotification.vue'
import moment from 'moment-timezone'
import { appEnv, formatFixedDecimalsNoUselessZero } from '@/utils'

@Component({
	components: {
		Code2FADialog,
		ModalNotification,
	},
})
export default class SpotOrders extends Vue {
	@Prop()
	productCurrency!: string

	@Prop()
	productType!: string

	@Prop()
	productPrecision!: number

	@Prop()
	unitPriceCurrency!: string

	@Prop()
	unitPriceType!: string

	@Prop()
	unitPricePrecision!: number

	private conf: Conf | null = null
	private creating = false
	private headers = [
		{ align: 'left', sortable: false, text: this.$t('spotOrderTable.header.at'), value: 'createdAt' },
		{ align: 'center', sortable: false, text: this.$t('pastSpotOrderTable.header.completion'), value: 'status' },
		{ align: 'right', sortable: false, text: this.$t('spotOrderTable.header.productAmount'), value: 'product.value' },
		{ align: 'right', sortable: false, text: this.$t('spotOrderTable.header.unitPriceAmount'), value: 'unitPrice.value' },
		{ align: 'right', sortable: false, text: this.$t('spotOrderTable.header.total'), value: 'total' },
		{ align: 'center', sortable: false, text: this.$t('spotOrderTable.header.delete'), value: 'delete' },
	]
	private isNotificationSuccessVisible = false
	private isNotificationVisible = false
	private itemToDelete: string | null = null
	private itemToShow: string | null = null
	private loading = true
	private precedenceSpotRecord: string | null = null
	private show2FADialog = false
	private showPrecedenceSpotRecord = false
	private spotOrdersBuy: SpotOrder[] = []
	private spotOrdersSell: SpotOrder[] = []
	private total = 0

	private canDelete (): boolean {
		if (this.$store.state.owner.locked === 'HARD') {
			return false
		}
		return true
	}

	private visibleDelete (): boolean {
		return true
	}

	private precedenceEnabled (): boolean {
		return appEnv() === 'staging'
	}

	async mounted (): Promise<void> {
		await this.refresh()
	}

	private getSellOrdersType (type: SpotOrderType): SpotOrder[] {
		const result = []
		if (type === 'BUY') {
			for (const o of this.spotOrdersBuy) {
				if (o.type === type) {
					result.push(o)
				}
			}
		} else {
			for (const o of this.spotOrdersSell) {
				if (o.type === type) {
					result.push(o)
				}
			}
		}
		return result
	}

	private percent (item: any): string {
		let result = new BigNumber(0)
		if (!item.productValueCreated || item.productValueCreated === 0) {
			result = new BigNumber(100)
		}
		if (item.status === 'OPEN'){
			result = new BigNumber(item.productValueCreated).minus(item.product.value).dividedBy(item.productValueCreated).times(100)
		} else if (item.status === 'COMPLETED') {
			result = result = new BigNumber(100)
		} else if (item.status === 'CANCELED'){
			result = new BigNumber(item.productValueCreated).minus(item.productValueCanceled).dividedBy(item.productValueCreated).times(100)
		}
		return formatFixedDecimalsNoUselessZero(this.$i18n, new BigNumber(result.toFixed(1, BigNumber.ROUND_DOWN)), 1)
	}

	async refresh (): Promise<void> {
		this.loading = true
		this.conf = await getAPIConfiguration()
		await this.refreshOrders()
		this.loading = false
	}

	async refreshOrders (): Promise<void> {
		this.loading = true
		const result1 = await getSpotOrders(this.$store.state.jwt, this.productCurrency, this.productType, this.unitPriceCurrency, this.unitPriceType, 'unitPrice.value:DESC&sort=createdAt:ASC')
		if (result1) {
			this.spotOrdersBuy = result1.results ? result1.results : []
			this.total = result1.total
		}
		const result2 = await getSpotOrders(this.$store.state.jwt, this.productCurrency, this.productType, this.unitPriceCurrency, this.unitPriceType, 'unitPrice.value:ASC&sort=createdAt:ASC')
		if (result2) {
			this.spotOrdersSell = result2.results ? result2.results : []
			this.total = result2.total
		}
		this.loading = false
	}

	private formatDate (date: Date): string {
		return moment(date)
			.tz(moment.tz.guess())
			.format("YYYY-MM-DD HH:mm:ss")
	}

	getTotalPrice (item: SpotOrder): number {
		return new BigNumber(item.product.value).multipliedBy(new BigNumber(item.unitPrice.value)).toNumber()
	}

	async deleteOffer (object: any): Promise<void> {
		this.show2FADialog = false
		const	code2FA = object.code
		if (this.itemToDelete) {
			try {
				await deleteSpotOrder(this.$store.state.jwt, code2FA, this.itemToDelete)
				this.isNotificationSuccessVisible = true
			} catch (error) {
				// to make sure the delete button state is enabled again
				this.itemToDelete = null
			}
		}
		await this.refresh()
	}

	onDeleteClick (item: any): void {
		this.itemToDelete = item.id
		this.isNotificationVisible = true
	}

	async onGetRecord (item: any): Promise<void> {
		this.itemToShow = item.id
		this.precedenceSpotRecord = await getSpotPrecedence(this.$store.state.jwt, item.id)
		this.showPrecedenceSpotRecord = true
	}

	async onGetCertificate (item: any): Promise<void> {
		// this.itemToShow = item.id
		await getSpotPrecedenceCertificate(this.$store.state.jwt, item.id)
		// this.showPrecedenceSpotRecord = true
	}

	onConfirm (): void {
		this.isNotificationVisible = false
		this.show2FADialog = true
	}

	onNotificationClose (): void {
		if (this.itemToDelete) {
			this.itemToDelete = null
		}
		this.isNotificationVisible = false
	}

	@Watch('showPrecedenceSpotRecord')
	onUpdateShowPrecedenceSpotRecord (newval: boolean, oldval: boolean): void {
		if (!newval) {
			this.itemToShow = null
			this.precedenceSpotRecord = null
		}
	}
}
