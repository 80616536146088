














































import {
	getAPIConfiguration,
	getPastSpotOrders,
	SpotOrder,
} from '@/clients/cpinblocks'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Conf } from '@/models/Conf'
import BigNumber from 'bignumber.js'
import Code2FADialog from '@/components/Code2FADialog.vue'
import ModalNotification from '@/components/ModalNotification.vue'
import moment from 'moment-timezone'
import {formatFixedDecimalsNoUselessZero} from "@/utils";

@Component({
	components: {
		Code2FADialog,
		ModalNotification,
	},
})
export default class SpotOrdersPast extends Vue {
	@Prop()
	productCurrency!: string

	@Prop()
	productType!: string

	@Prop()
	productPrecision!: number

	@Prop()
	unitPriceCurrency!: string

	@Prop()
	unitPriceType!: string

	@Prop()
	unitPricePrecision!: number

	private headers = [
		{ align: 'left', sortable: false, text: this.$t('pastSpotOrderTable.header.at'), value: 'updatedAt' },
		{ align: 'center', sortable: false, text: this.$t('pastSpotOrderTable.header.type'), value: 'type' },
		{ align: 'center', sortable: false, text: this.$t('pastSpotOrderTable.header.completion'), value: 'status' },
		{ align: 'right', sortable: false, text: this.$t('pastSpotOrderTable.header.numberOfTrades'), value: 'trades' },
		{ align: 'right', sortable: false, text: this.$t('pastSpotOrderTable.header.productAmount'), value: 'productValueCreated' },
		{ align: 'right', sortable: false, text: this.$t('pastSpotOrderTable.header.unitPriceAmount'), value: 'unitPrice.value' },
		{ align: 'right', sortable: false, text: this.$t('pastSpotOrderTable.header.total'), value: 'total' },
	]

	private conf: Conf | null = null
	private spotOrders: SpotOrder[] = []
	private loading = true
	private total = 0

	async mounted (): Promise<void> {
		await this.refresh()
	}

	private getPastOrders (): SpotOrder[] {
		const result = []
		for (const o of this.spotOrders) {
			result.push(o)
		}
		return result
	}

	private percent (item: any): string {
		let result = new BigNumber(0)
		if (!item.productValueCreated || item.productValueCreated === 0) {
			result = new BigNumber(100)
		}
		if (item.status === 'OPEN'){
			result = new BigNumber(item.productValueCreated).minus(item.product.value).dividedBy(item.productValueCreated).times(100)
		} else if (item.status === 'COMPLETED') {
			result = result = new BigNumber(100)
		} else if (item.status === 'CANCELED'){
			result = new BigNumber(item.productValueCreated).minus(item.productValueCanceled).dividedBy(item.productValueCreated).times(100)
		}
		return formatFixedDecimalsNoUselessZero(this.$i18n, new BigNumber(result.toFixed(1, BigNumber.ROUND_DOWN)), 1)
	}

	async refresh (): Promise<void> {
		this.loading = true
		this.conf = await getAPIConfiguration()
		await this.refreshOrders()
		this.loading = false
	}

	async refreshOrders (): Promise<void> {
		this.loading = true
		const result = await getPastSpotOrders(this.$store.state.jwt, this.productCurrency, this.productType, this.unitPriceCurrency, this.unitPriceType, 'updatedAt:DESC')
		if (result) {
			this.spotOrders = result.results ? result.results : []
			this.total = result.total
		}
		this.loading = false
	}

	private formatDate (date: Date): string {
		return moment(date)
			.tz(moment.tz.guess())
			.format("YYYY-MM-DD HH:mm:ss")
	}

	getTotalPrice (item: SpotOrder): number {
		let total = new BigNumber(0)
		if (!item.productValueCreated) {
			return 0
		}
		if (item.status === 'COMPLETED') {
			total = new BigNumber(item.productValueCreated).multipliedBy(new BigNumber(item.unitPrice.value))
		} else if (item.status === 'CANCELED' && item.productValueCanceled) {
			total = new BigNumber(item.productValueCreated).minus(item.productValueCanceled).multipliedBy(new BigNumber(item.unitPrice.value))
		}
		if (item.type === 'BUY') {
			total = total.multipliedBy(-1)
		}
		return total.toNumber()
	}
}
