













import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import type { ApexOptions } from 'apexcharts'
import VueApexCharts from 'apexcharts'
import BigNumber from 'bignumber.js'
import { DOM } from '@/clients/cpinblocks'

interface IDepthChart extends VueApexCharts, Vue {
	updateSeries(newSeries: any, animate?: boolean): Promise<void>;
}

@Component({
	components: {},
})
export default class SpotDOMChart extends Vue {
	@Prop() currency!: Record<string, string>;
	@Prop() dom!: DOM;
	@Prop() precision!: number;
	@Prop() currentPrice!: BigNumber;

	private key = 0
	private nbValuesBeforeAndAfterPrice = 16

	private get BNPrecisionFromPrecisionParam (): number {
		switch (this.precision) {
			case 0.0001:
				return 4
			case 0.001:
				return 3
			case 0.01:
				return 2
			case 0.1:
				return 1
			default:
				return 0
		}
	}

	private get currentCurrency (): string {
		return `${this.currency.productCurrency} / ${this.currency.unitPriceCurrency}`
	}

	private get buyData (): number[] {
		const tmp: number[] = []
		const reverseDomBuys = this.dom.buys.reverse()
		let i = 0
		for (let j = 0; j < reverseDomBuys.length; j++) {
			i = j
			if (new BigNumber(reverseDomBuys[i].price).gte(new BigNumber(this.xAxis[0]))) {
				break
			}
		}
		for (const n of this.xAxis) {
			if (reverseDomBuys.length > 0 && new BigNumber(reverseDomBuys[i].price).eq(new BigNumber(n))) {
				tmp.push(Number.parseFloat(new BigNumber(reverseDomBuys[i].quantity).toFixed(this.BNPrecisionFromPrecisionParam)))
				i++
				if (i >= reverseDomBuys.length) {
					break
				}
			} else {
				tmp.push(0)
			}
		}
		tmp.push(0)
		let result: number[] = []
		let sum = 0
		for (const n of tmp.reverse()) {
			sum += n
			result.push(sum)
		}
		result.pop()
		result = result.reverse()
		return result
	}

	private get sellData (): (number | null)[] {
		const result: (number | null)[] = []
		let i = 0
		let somme = new BigNumber(0)
		if (this.dom.sells.length > 0) {
			for (const n of this.xAxis) {
				if (!new BigNumber(this.dom.sells[i].price).eq(new BigNumber(n))) {
					if (somme.eq(0)) {
						result.push(null)
					} else {
						result.push(somme.toNumber())
					}
				} else {
					somme = somme.plus(this.dom.sells[i].quantity)
					result.push(somme.toNumber())
					i++
					if (i >= this.dom.sells.length) {
						break
					}
				}
			}
		}
		if (result.length < this.xAxis.length) {
			result.push(somme.toNumber())
		}
		return result
	}

	private get xAxis (): string[] {
		const res = []
		const currPrice = new BigNumber(this.currentPrice)
		const precision = new BigNumber(this.precision)
		let min = currPrice.minus(precision.multipliedBy(this.nbValuesBeforeAndAfterPrice))
		if (min.lt(0)) {
			min = new BigNumber(0)
		}
		const max = min.plus(precision.multipliedBy(this.nbValuesBeforeAndAfterPrice).multipliedBy(2))
		let x = min
		while (x.lte(max)) {
			res.push(x.toFixed(this.BNPrecisionFromPrecisionParam))
			x = x.plus(this.precision)
		}
		return res.map(String)
	}

	private get options(): ApexOptions {
		return {
			colors: ['#00ff00', '#ff0000'],
			chart: {
				toolbar: {
					show: false,
				},
				type: 'area',
				width: '100%',
				zoom: {
					enabled: false,
				},
			},
			title: {
				align: 'left',
				style: {
					color: this.$store.state.darkMode ? 'white' : 'black',
				},
				text: this.currentCurrency,
			},
			legend: {
				labels: {
					colors: this.$store.state.darkMode ? 'white' : 'black',
				},
			},
			xaxis: {
				categories: this.xAxis,
				labels: {
					style: {
						colors: this.$store.state.darkMode ? 'white' : 'black',
					}
				},
				tooltip: {
					enabled: false,
				},
				type: 'category',
			},
			yaxis: {
				labels: {
					style: {
						colors: this.$store.state.darkMode ? 'white' : 'black',
					}
				},
				min: 0,
			},
			dataLabels: {
				enabled: false,
			},
			stroke: {
				curve: 'stepline',
				width: 2,
			},
			noData: {
				text: 'Loading...',
			},
			tooltip: {
				enabled: false,
			},
		}
	}

	private series: Array<any> = [
		{
			id: 'Bids',
			name: 'Buy',
			data: this.buyData,
		},
		{
			id: 'Asks',
			name: 'Sell',
			data: this.sellData,
		},
	]

	private updateChart (): void {
		this.series = [
			{
				id: 'Bids',
				name: 'Buy',
				data: this.buyData,
			},
			{
				id: 'Asks',
				name: 'Sell',
				data: this.sellData,
			},
		]

		;(this.$refs.depthChart as IDepthChart).updateOptions({
			series: this.series,
			xaxis: {
				categories: this.xAxis,
			},
		})
	}

	@Watch('$store.state.darkMode')
	private refresh (): void {
		this.key++
	}

	@Watch('dom')
	private restoreAutoRefresh (value: string[]): void {
		if (value) {
			this.updateChart()
		}
	}
}
