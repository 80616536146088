




























































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { getDOM, DOM, getSpotPrice, getSpotPairs, getAccounts, TradePair } from '@/clients/cpinblocks'
import { alertRawError, env, formatFixedDecimalsNoUselessZero } from '@/utils'
import BigNumber from 'bignumber.js'
import { Account } from '@/models/Account'
import AddSpotOrder from '@/components/AddSpotOrder.vue'
import AddCheckout from '@/components/AddCheckout.vue'
import { Currency, TokenType } from '@/types'
import SpotDOMChart from '@/components/SpotDOMChart.vue'
import SpotDOMTable from '@/components/SpotDOMTable.vue'
import SpotLastTrades from '@/components/SpotLastTrades.vue'
import SpotOrders from '@/components/SpotOrders.vue'
import SpotOrdersPast from '@/components/SpotOrdersPast.vue'
import SpotPriceHistoryCharts from '@/components/SpotPriceHistoryChart.vue'

@Component({
  components: { AddCheckout, AddSpotOrder, SpotDOMTable, SpotOrders, SpotDOMChart, SpotLastTrades, SpotOrdersPast, SpotPriceHistoryCharts },
})
export default class SpotPair extends Vue {
  @Prop() pair!: string

  private accounts: Account[] = []
  private currentPrecisionIndex = 2
  private currentPrice: BigNumber | null = null
  private domData: DOM | null = null
  private loading = true
  private max = new BigNumber('0')
  private minPrice: BigNumber | null = null
  private minUnitPrice: BigNumber | null = null
  private maxUnitPrice: BigNumber | null = null
  private openedTab = 'dom'
  private precisions = [0.0001, 0.001, 0.01, 0.1, 1]
  private productPrecision = 0
  private showCheckout = false
  private showDialog = false
  private spotPairs: TradePair[] = []
  private type = ''
  private unitPricePrecision = 0

  get appEnv (): string {
    return env('VUE_APP_ENV')
  }

  get currentPrecision(): number {
    return this.precisions[this.currentPrecisionIndex]
  }

  get currencyDisplayed (): string {
    return this.currentPair.unitPriceCurrency + (this.currentPair.unitPriceType !== 'MAIN' ? ' (' + this.currentPair.unitPriceType + ')' : '')
  }

  get productDisplayed (): string {
    return this.currentPair.productCurrency + (this.currentPair.productType !== 'MAIN' ? ' (' + this.currentPair.productType + ')' : '')
  }

  get priceToDisplay (): string {
    if (this.currentPrice) return '— €' + formatFixedDecimalsNoUselessZero(this.$i18n, new BigNumber(this.currentPrice), 4)
    return ''
  }

  private getBalance (currency: Currency, type: TokenType): BigNumber | null {
    for (const a of this.accounts) {
      if (a.currency === currency && a.type === type) {
        return a.balance
      }
    }
    return null
  }

  private isBuyVisible (): boolean {
    return this.$keycloak.authenticated === true && this.$store.getters.isAtLeastKYCLevel1
  }

  private isSellVisible (): boolean {
    return this.$keycloak.authenticated === true && this.$store.getters.isAtLeastKYCLevel1 && this.currentPair.checkout === 'DISABLED'
  }

  get hasIBXE (): boolean {
    const balance = this.getBalance('IBXE', 'MAIN')
    if  (balance !== null && balance.gt(0)) {
      return true
    }
    return false
  }

  get isSellDisabled (): boolean {
    const balance = this.getBalance(this.currentPair.productCurrency as Currency, this.currentPair.productType as TokenType)
    if  (balance === null || balance.eq(0)) {
      return true
    }
    return false
  }

  private async openCheckoutIBXE (): Promise<void> {
    this.showCheckout = true
  }

  get currentPair (): Record<string, string> {
    const splittedPair = this.pair.split('_')
    const product = splittedPair[0]
    const productCurrency = product.split('-')[0]
    let productType = 'MAIN'
    if (product.split('-').length === 2) {
      productType = product.split('-')[1]
    }
    const unitPrice = splittedPair[1]
    const unitPriceCurrency = unitPrice.split('-')[0]
    let unitPriceType = 'MAIN'
    if (unitPrice.split('-').length === 2) {
      unitPriceType = unitPrice.split('-')[1]
    }
    let checkout = 'DISABLED'
    for (const o of this.spotPairs) {
      if (o.productCurrency === productCurrency && o.unitPriceCurrency === unitPriceCurrency) {
        checkout = o.checkout ? o.checkout : checkout
      }
    }
    return {
      productCurrency: productCurrency,
      productType: productType,
      unitPriceCurrency: unitPriceCurrency,
      unitPriceType: unitPriceType,
      checkout: checkout
    }
  }

  @Watch('currentPrecisionIndex')
  async onPrecisionChange (newv: number, oldv: number): Promise<void> {
    this.domData = await getDOM(this.currentPair.productCurrency, this.currentPair.productType, this.currentPair.unitPriceCurrency, this.currentPair.unitPriceType, this.currentPrecision)
  }

  async mounted (): Promise<void> {
    this.spotPairs = await getSpotPairs()
    await this.refresh()
  }

  async refresh (): Promise<void> {
    this.loading = true
    if (this.$keycloak.authenticated) {
      this.accounts = await getAccounts(this.$store.state.jwt, false)
    }
    try {
      for (const o of this.spotPairs) {
        if (o.productCurrency === this.currentPair.productCurrency && o.unitPriceCurrency === this.currentPair.unitPriceCurrency) {
          this.unitPricePrecision = o.unitPricePrecision
          this.productPrecision = o.productPrecision
          this.minPrice = o.minPrice ?? null
          this.minUnitPrice = o.minUnitPrice ?? null
          this.maxUnitPrice = o.maxUnitPrice ?? null
        }
      }
      this.domData = await getDOM(this.currentPair.productCurrency, this.currentPair.productType, this.currentPair.unitPriceCurrency, this.currentPair.unitPriceType, this.currentPrecision)
      this.currentPrice = await getSpotPrice(this.currentPair.productCurrency, this.currentPair.productType, this.currentPair.unitPriceCurrency, this.currentPair.unitPriceType)
      this.loading = false
    } catch (error: any) {
      alertRawError(error.message)
    }
    this.loading = false
  }

  private getMax (currency: string, type: string): BigNumber | null {
    for (const a of this.accounts) {
      if (a.currency === currency && a.type === type) {
        return a.balance
      }
    }
    return null
  }

  private onBuy (): void {
    this.type = 'BUY'
    this.max = this.getMax(this.currentPair.unitPriceCurrency, this.currentPair.unitPriceType) ?? new BigNumber('0')
    this.showDialog = true
  }

  private onSell (): void {
    this.type = 'SELL'
    this.max = this.getMax(this.currentPair.productCurrency, this.currentPair.productType) ?? new BigNumber('0')
    this.showDialog = true
  }

  async onOrderCreated (): Promise<void> {
    this.showDialog = false
    await this.refresh()
  }

  async onOrderPassed (): Promise<void> {
    this.showDialog = false
    await this.refresh()
  }

  async onOrderFailed (): Promise<void> {
    this.showDialog = false;
    await this.refresh()
  }
}

// FIXME
// Local styling fix due to Vuetify open bug https://github.com/vuetifyjs/vuetify/issues/12748
