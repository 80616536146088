var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ma-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":12}},[_c('v-card',{attrs:{"elevation":"2"}},[_c('v-toolbar',{attrs:{"dark":"","color":"primary","flat":""}},[_c('v-toolbar-title',[_c('span',[_vm._v(" "+_vm._s(_vm.$t('pastSpotOrderTable.title'))+" ")])])],1),_c('v-data-table',{staticStyle:{"cursor":"pointer"},attrs:{"headers":_vm.headers,"item-key":"id","items":_vm.getPastOrders(),"loading":_vm.loading,"hide-default-footer":"","items-per-page":1000},scopedSlots:_vm._u([{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.updatedAt))+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.type === 'SELL')?_c('v-span',{staticClass:"orderTypeSell"},[_vm._v(_vm._s(item.type))]):(item.type === 'BUY')?_c('v-span',{staticClass:"orderTypeBuy"},[_vm._v(_vm._s(item.type))]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 'COMPLETED')?[_c('v-progress-circular',{attrs:{"color":"green","value":_vm.percent(item),"rotate":270}},[_c('span',{staticStyle:{"font-size":"0.7em"}},[_vm._v(_vm._s(_vm.percent(item)))])])]:(item.status === 'CANCELED')?[_c('v-progress-circular',{attrs:{"color":"orange","value":_vm.percent(item),"rotate":270}},[_c('span',{staticStyle:{"font-size":"0.7em"}},[_vm._v(_vm._s(_vm.percent(item)))])])]:_vm._e()]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [(_vm.getTotalPrice(item) === 0)?_c('span',[_vm._v(_vm._s(_vm.getTotalPrice(item)))]):(_vm.getTotalPrice(item) > 0)?_c('span',{staticClass:"green--text"},[_vm._v("+"+_vm._s(_vm.getTotalPrice(item)))]):(_vm.getTotalPrice(item) < 0)?_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.getTotalPrice(item)))]):_vm._e()]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }