







































import { Vue, Prop, Component, Watch } from 'vue-property-decorator'
import type { ApexOptions } from 'apexcharts'
import chart from 'vue-apexcharts'
import BigNumber from 'bignumber.js'
import {
	IBDuration,
	getSpotPriceHistorySpan,
	SpotPriceHistory
} from '@/clients/cpinblocks'
import VueApexCharts from 'apexcharts'
import moment, { unitOfTime } from 'moment'
import { appEnv } from '@/utils'

interface historyChart extends VueApexCharts, Vue {
	updateSeries(newSeries: any, animate?: boolean): Promise<void>;
}

@Component({
	components: { chart },
})
export default class SpotPriceHistoryChart extends Vue {
	@Prop() productCurrency!: string
	@Prop() productType!: string
	@Prop() unitPriceCurrency!: string
	@Prop() unitPriceType!: string
	@Prop() currentPrice!: string | undefined

	private periodValueIndex = 1
	private periodValues = ['last year', 'last month', 'last week', 'last day', 'last hour']
	private candleIntervalIndex = 6
	private candleIntervalValues = ['1m', '5m', '15m', '1h', '6h', '12h', '1d']
	private priceHistory: SpotPriceHistory | null = null
	private chartEndTime: Date = new Date()
	private chartStartTime: Date = moment().subtract(1, 'month').toDate()
	private loading = true

	private getAmount (interval: IBDuration): number {
		switch (interval) {
			case '1m':
			case '1h':
				return 1
			case '1d':
				// because day notion is relative to timezone
				return 24
			case '5m':
				return 5
			case '6h':
				return 6
			case '12h':
				return 12
			case '15m':
				return 15
		}
		return 1
	}

	private getPeriodicity (interval: IBDuration): unitOfTime.DurationConstructor {
		switch (interval) {
			case '1m':
			case '5m':
			case '15m':
				return 'minutes'
			case '1h':
			case '6h':
			case '12h':
				return 'hours'
			// because day notion is relative to timezone
			case '1d':
				return 'hours'
			default:
				throw new Error('unexpected error')
		}
	}

	private get chartValue () {
		const data = this.priceHistory && this.priceHistory.data.map(({ startAt, openPrice, closePrice, high, low }) => ({
			x: moment(startAt).toDate(),
			y: [new BigNumber(openPrice), new BigNumber(high), new BigNumber(low), new BigNumber(closePrice)],
		}))
		// data?.map(d => console.log('-> ' + d.x.toISOString()))
		const endDate = this.priceHistory?.endTime // moment().startOf(this.getPeriodicity(this.candleIntervalValues[this.candleIntervalIndex] as IBDuration))
		const result = []
		let cursor = 0
		let nextDate
		let lastClosePrice = null
		if (data !== null && data.length > 0) {
			nextDate = moment(data[0].x).clone()
			while (nextDate.isSameOrBefore(endDate)) {
				if (cursor < data.length && nextDate.toDate().getTime() === data[cursor].x.getTime()) {
					// console.log('found ' + nextDate.toDate() + ' ' + data[cursor].x)
					result.push({
						x: data[cursor].x,
						y: [ lastClosePrice !== null ? lastClosePrice : data[cursor].y[0], data[cursor].y[1], data[cursor].y[2], data[cursor].y[3] ]
					})
					lastClosePrice = data[cursor].y[3]
					cursor++
				} else {
					// console.log('not found ' + nextDate.toDate())
					result.push({
						x: nextDate,
						y: [ lastClosePrice, lastClosePrice, lastClosePrice, lastClosePrice ],
					})
				}
				nextDate.clone().add('1d')
				nextDate = nextDate.clone().add(
					this.getAmount(this.candleIntervalValues[this.candleIntervalIndex] as IBDuration),
					this.getPeriodicity(this.candleIntervalValues[this.candleIntervalIndex] as IBDuration)
				)
			}
		} else {
			// TODO: check that 100 is the right value at API level
			// we did not receive any data, nothing happened in the last 100 times the interval requested
			nextDate = moment(this.priceHistory?.startTime).clone()
			while (nextDate.isSameOrBefore(endDate)) {
				result.push({
					x: nextDate,
					y: [this.currentPrice, this.currentPrice, this.currentPrice, this.currentPrice],
				})
				nextDate = nextDate.clone().add(
					this.getAmount(this.candleIntervalValues[this.candleIntervalIndex] as IBDuration),
					this.getPeriodicity(this.candleIntervalValues[this.candleIntervalIndex] as IBDuration)
				)
			}
		}
		return result
	}

	private get currencyDisplayed (): string {
		return this.unitPriceCurrency + (this.unitPriceType !== 'MAIN' ? ' (' + this.unitPriceType + ')' : '')
	}

	private get productDisplayed (): string {
		return this.productCurrency + (this.productType !== 'MAIN' ? ' (' + this.productType + ')' : '')
	}

	private series = [{
		data: this.chartValue,
	}]

	private async zoomed (chart: any, options: any): Promise<void> {
		// appEnv() === 'staging' ? console.log(chart) : null
		// appEnv() === 'staging' ? console.log(options) : null
		this.periodValueIndex = -1
		this.chartStartTime = new Date(options.xaxis.min)
		this.chartEndTime = new Date(options.xaxis.max)
	  this.candleIntervalIndex = 0
	  while (!this.isIntervalAvailable(this.candleIntervalValues[this.candleIntervalIndex]) && this.candleIntervalIndex < this.candleIntervalValues.length - 1) {
		  this.candleIntervalIndex++
	  }
		await this.updateIntervalFromStartEnd()
	}

	private get options(): ApexOptions {
		return {
			noData: {
				text: 'Loading data (if any) ...',
				align: 'center',
				verticalAlign: 'middle',
				offsetX: 0,
				offsetY: 0,
				style: {
					// color: undefined,
					fontSize: '14px',
					// fontFamily: undefined,
				},
			},
			chart: {
				events: {
					zoomed: this.zoomed,
				},
				redrawOnWindowResize: true,
				toolbar: {
					// autoSelected: 'pan',
					// tools: {
					// 	download: false,
					// 	selection: false,
					// 	zoom: false,
					// 	zoomin: false,
					// 	zoomout: false,
					// 	pan: false,
					// 	reset: false,
					// },
					show: false,
				},
				type: 'candlestick',
			},
			title: {
				text: this.productDisplayed + ' / ' + this.currencyDisplayed + ' over time',
				style: {
					color: this.$store.state.darkMode ? 'white' : 'black',
				},
				align: 'left',
			},
			tooltip: {
				enabled: true,
				custom: function ({seriesIndex, dataPointIndex, w}) {
					const t = moment(w.globals.seriesX[seriesIndex][dataPointIndex]).format("MMM DD HH:mm")
					const o = w.globals.seriesCandleO[seriesIndex][dataPointIndex]
					const h = w.globals.seriesCandleH[seriesIndex][dataPointIndex]
					const l = w.globals.seriesCandleL[seriesIndex][dataPointIndex]
					const c = w.globals.seriesCandleC[seriesIndex][dataPointIndex]
					return (
						'<div class="ma-2 apexcharts-tooltip-candlestick">' +
						'<div style="color: black; font-weight: bold"><span>' +
						t +
						'</span></div>' +
						'<div style="color: black;">Open: <span>' +
						o +
						'</span></div>' +
						'<div style="color: black;">High: <span>' +
						h +
						'</span></div>' +
						'<div style="color: black;">Low: <span>' +
						l +
						'</span></div>' +
						'<div style="color: black;">Close: <span>' +
						c +
						'</span></div>' +
						'</div>'
					)
				}
			},
			xaxis: {
				axisBorder: {
					show: true,
					color: this.$store.state.darkMode ? 'white' : 'black',
				},
				labels: {
					style: {
						colors: this.$store.state.darkMode ? 'white' : 'black',
					}
				},
				tickAmount: 10,
				type: 'datetime',
			},
			yaxis: {
				min: 0,
				labels: {
					style: {
						colors: this.$store.state.darkMode ? 'white' : 'black',
					}
				},
				tooltip: {
					enabled: true,
				},
			}
		}
	}

	async mounted (): Promise<void> {
		this.priceHistory = await getSpotPriceHistorySpan(this.productCurrency, this.productType, this.unitPriceCurrency, this.unitPriceType, this.chartStartTime, this.chartEndTime, this.candleIntervalValues[this.candleIntervalIndex] as IBDuration)
		await this.redraw()
	}

	private getPeriodFromValue (period: string) {
		for (let i=0; i<this.periodValues.length; i++) {
			if (this.periodValues[i] === period) {
				return i
			}
		}
		return this.periodValues.length - 1
	}

	private getIndexFromValue (interval: IBDuration): number {
		for (let i=0; i<this.candleIntervalValues.length; i++) {
			if (this.candleIntervalValues[i] === interval) {
				return i
			}
		}
		return this.candleIntervalValues.length - 1
	}

	private async updateIntervalFromStartEnd (): Promise<void> {
		const duration = moment.duration(moment(this.chartEndTime).diff(this.chartStartTime))
		let interval
		if (duration.asMonths() > 3) {
			// 1 month < duration
			interval = '1d'
		} else if (duration.asMonths() > 1) {
			// 1 month < duration
			interval = '1d'
		} else if (duration.asWeeks() > 1) {
			// 1 week < duration < 1 month
			interval = '12h'
		} else if (duration.asDays() > 1) {
			// 1 day < duration < 1 week
			interval = '6h'
		} else if (duration.asMinutes() > 100) {
			// duration < 1 day
			interval = '1h'
		} else {
			interval = '1m'
		}
		await this.updateInterval(interval)
	}

	// when click on 1m, 1h, 1d, 1w ...
	private async updateInterval (interval: any | null): Promise<void> {
		this.candleIntervalIndex = this.getIndexFromValue(interval)
		this.loading = true
		// console.log(this.candleIntervalValues[this.candleIntervalIndex] + ' from ' + this.chartStartTime.toISOString() + ' to ' + this.chartEndTime.toISOString())
		this.priceHistory = await getSpotPriceHistorySpan(this.productCurrency, this.productType, this.unitPriceCurrency, this.unitPriceType, this.chartStartTime, this.chartEndTime, this.candleIntervalValues[this.candleIntervalIndex] as IBDuration)
		this.loading = false
	}

	private getAmountFromPeriod (period: string): number {
		return 1
	}

	private getPeriodicityFromPeriod (period: string):  unitOfTime.DurationConstructor {
		return period.split(' ')[1] as unitOfTime.DurationConstructor
	}

	private isIntervalAvailable (interval: string): boolean {
		const duration = moment.duration(moment(this.chartEndTime).diff(this.chartStartTime))
		if (duration.asMonths() > 3) {
			// 3 month < duration
			return ['1d'].indexOf(interval) >= 0
		} else if (duration.asMonths() > 1) {
			// 1 month < duration < 3 months
			return ['12h', '1d'].indexOf(interval) >= 0
		} else if (duration.asWeeks() > 1) {
			// 1 week < duration < 1 month
			return ['6h', '12h', '1d'].indexOf(interval) >= 0
		} else if (duration.asDays() > 1) {
			// 1 day < duration < 1 week
			return ['1h', '6h', '12h', '1d'].indexOf(interval) >= 0
		} else if (duration.asHours() > 1) {
			// duration < 1 day
			return ['5m', '15m', '1h'].indexOf(interval) >= 0
		} else {
			// duration < 1 hour
			return ['1m', '5m', '15m'].indexOf(interval) >= 0
		}
	}

	// when click on last week or last day ...
	private async updatePeriod (period: string): Promise<void> {
		this.periodValueIndex = this.getPeriodFromValue(period)
		this.loading = true
		this.chartEndTime = new Date()
		this.chartStartTime = moment().subtract(this.getAmountFromPeriod(period), this.getPeriodicityFromPeriod(period)).toDate()
		this.candleIntervalIndex = 0
		while (!this.isIntervalAvailable(this.candleIntervalValues[this.candleIntervalIndex]) && this.candleIntervalIndex < this.candleIntervalValues.length - 1) {
			this.candleIntervalIndex++
		}
		// console.log(this.candleIntervalValues[this.candleIntervalIndex] + ' from ' + this.chartStartTime.toISOString() + ' to ' + new Date().toISOString())
		this.priceHistory = await getSpotPriceHistorySpan(this.productCurrency, this.productType, this.unitPriceCurrency, this.unitPriceType, this.chartStartTime, this.chartEndTime, this.candleIntervalValues[this.candleIntervalIndex] as IBDuration)
		this.candleIntervalIndex = this.getIndexFromValue(this.priceHistory.interval)
		// console.log(this.priceHistory.interval + '  ' + this.candleIntervalIndex)
		this.loading = false
	}

	@Watch('$store.state.darkMode')
	@Watch('priceHistory')
	private async redraw () : Promise<void> {
		// console.log('redraw')
		await (this.$refs.historyChart as historyChart).updateSeries(
			[{
				data: this.chartValue,
			}],
			true
		)
	}
}
