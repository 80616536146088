




















































































import { Vue, Component, Prop } from 'vue-property-decorator'
import BigNumber from 'bignumber.js'
import { DOM, DOMBuySell, SpotOrderType } from '@/clients/cpinblocks'

class DOMBuySellLine {
	quantity!: string
	total!: string
	type!: SpotOrderType
	price!: string
}

@Component({
	components: {},
})
export default class SpotDOMTable extends Vue {
	@Prop() pair!: Record<string, string>
	@Prop() precision!: number
	@Prop() dom!: DOM
	@Prop() loading!: boolean

	headers = [
		{
			align: 'left',
			sortable: false,
			text: this.$t('spot.orderBook.unitBuy', { currency: this.pair.unitPriceCurrency }),
			value: 'price',
		},
		{
			align: 'right',
			sortable: false,
			text: this.$t('spot.orderBook.quantity', { token: this.pair.productCurrency }),
			value: 'quantity',
		},
	]

	private selectedItem: any | null = null
	private showBuyTooltip: any = {}
	private showSellTooltip: any = {}
	private sumProduct: string = ''
	private sumPaymentCurrency: string = ''
	private averagePrice: string = ''

	selectItem (item: any): void {
		this.selectedItem = item
		this.sumProduct = this.getSumProduct(item, item.type)
		this.sumPaymentCurrency = this.getSumPaymentCurrency(item, item.type)
		this.averagePrice = this.getAveragePrice(item, item.type)
		if (item.type === 'BUY') {
			this.showBuyTooltip[item.price] = true
		} else {
			this.showSellTooltip[item.price] = true
		}
	}

	unSelectItem (): void {
		this.showBuyTooltip = {}
		this.showSellTooltip = {}
		this.selectedItem = null
		this.sumProduct = ''
		this.sumPaymentCurrency = ''
		this.averagePrice = ''
	}

	private getSumProduct(item: any, type: string): string {
		let result = new BigNumber(0)
		if (this.selectedItem === null) {
			return ''
		}
		let orders: DOMBuySell[] = []
		switch (type) {
			case 'BUY':
				orders = this.dom.buys
				break
			case 'SELL':
				orders = this.dom.sells
				break
		}
		for (const o of orders) {
			if (type === 'BUY' && new BigNumber(this.selectedItem.price).lte(o.price)) {
				result = result.plus(o.quantity)
			} else if (type === 'SELL' && new BigNumber(this.selectedItem.price).gte(o.price)) {
				result = result.plus(o.quantity)
			}
		}
		return result.toFixed(2)
	}

	private getSumPaymentCurrency(item: any, type: string) : string {
		let result = new BigNumber(0)
		if (this.selectedItem === null) {
			return ''
		}
		let orders: DOMBuySell[] = []
		switch (type) {
			case 'BUY':
				orders = this.dom.buys
				break
			case 'SELL':
				orders = this.dom.sells
				break
		}
		for (let o of orders) {
			if (type === 'BUY' && new BigNumber(this.selectedItem.price).lte(o.price)) {
				result = result.plus(o.total)
			} else if (type === 'SELL' && new BigNumber(this.selectedItem.price).gte(o.price)) {
				result = result = result.plus(o.total)
			}
		}
		return result.toFixed(2)
	}

	private getAveragePrice (item: any, type: string): string {
		return new BigNumber(this.sumPaymentCurrency).dividedBy(this.sumProduct).toFixed(2)
	}

	getStyle (item: any, type: string): string {
		if (this.selectedItem === null) {
			return ''
		}
		if (this.selectedItem.type !== type) {
			return ''
		}
		switch (type) {
			case 'BUY':
				if (new BigNumber(this.selectedItem.price).lte(item.price)) {
					if (this.$store.state.darkMode) {
						return 'background-color: #606060'
					} else {
						return 'background-color: lightgrey'
					}
				}
				return ''
			case 'SELL':
				if (new BigNumber(this.selectedItem.price).gte(item.price)) {
					if (this.$store.state.darkMode) {
						return 'background-color: #606060'
					} else {
						return 'background-color: lightgrey'
					}
				}
		}
		return ''
	}

	itemRowBackground (item: DOMBuySellLine): string {
		return item && item.type === 'BUY' ? 'background-green' : 'background-red'
	}

	get fixedDigits (): number {
		if (this.precision < 1) return this.precision.toString().split('.')[1].length
		else return this.precision.toString().length
	}

	get buyOrders (): DOMBuySellLine[] {
		const res = []
		for (const o of this.dom.buys) {
			if (new BigNumber(o.quantity).toNumber() > 0) {
				const item = {
					quantity: new BigNumber(o.quantity).toFixed(this.fixedDigits),
					total: new BigNumber(o.quantity).multipliedBy(new BigNumber(o.price)).toFixed(this.fixedDigits),
					type: 'BUY',
					price: new BigNumber(o.price).toFixed(this.fixedDigits),
				} as DOMBuySellLine
				res.push(item)
			}
		}
		return res.sort((item1, item2) => new BigNumber(item1.price).minus(new BigNumber(item2.price)).toNumber()).reverse()
	}

	get sellOrders (): DOMBuySellLine[] {
		const res = []
		for (const o of this.dom.sells) {
			if (new BigNumber(o.quantity).toNumber() > 0) {
				const item = {
					quantity: new BigNumber(o.quantity).toFixed(this.fixedDigits),
					total: new BigNumber(o.quantity).multipliedBy(new BigNumber(o.price)).toFixed(this.fixedDigits),
					type: 'SELL',
					price: new BigNumber(o.price).toFixed(this.fixedDigits),
				} as DOMBuySellLine

				res.push(item)
			}
		}
		return res.sort((item1, item2) => new BigNumber(item1.price).minus(new BigNumber(item2.price)).toNumber())
	}
}
