var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{staticClass:"col-12 col-sm-6"},[_c('v-card',[_c('v-card-title',{staticClass:"white--text green pa-2"},[_vm._v(" "+_vm._s(_vm.$t('spot.orderBook.buys'))+" ")]),_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"item-key":"index","items":_vm.buyOrders,"items-per-page":30,"hide-default-footer":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item,style:(_vm.getStyle(item, 'BUY')),on:{"mouseover":function($event){return _vm.selectItem(item)},"mouseleave":function($event){return _vm.unSelectItem()}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var props = ref.props;
return [_c('td',_vm._b({staticClass:"text-left"},'td',props,false),[_vm._v(" "+_vm._s(item.price))]),_c('td',_vm._b({staticClass:"text-right"},'td',props,false),[_vm._v(" "+_vm._s(item.quantity))])]}}],null,true),model:{value:(_vm.showBuyTooltip[item.price]),callback:function ($$v) {_vm.$set(_vm.showBuyTooltip, item.price, $$v)},expression:"showBuyTooltip[item.price]"}},[(_vm.selectedItem !== null && _vm.selectedItem.price === item.price)?_c('div',[_c('div',[_vm._v("Average price: ~"+_vm._s(_vm.averagePrice))]),_c('div',[_vm._v("Sum "+_vm._s(_vm.pair.productCurrency)+": "+_vm._s(_vm.sumProduct))]),_c('div',[_vm._v("Sum "+_vm._s(_vm.pair.unitPriceCurrency)+": "+_vm._s(_vm.sumPaymentCurrency))])]):_vm._e()])],1)}),0)]}}])})],1)],1),_c('v-col',{staticClass:"col-12 col-sm-6"},[_c('v-card',[_c('v-card-title',{staticClass:"white--text red pa-2"},[_vm._v(" "+_vm._s(_vm.$t('spot.orderBook.sells'))+" ")]),_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"item-key":"index","items":_vm.sellOrders,"items-per-page":30,"hide-default-footer":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item,style:(_vm.getStyle(item, 'SELL')),on:{"mouseover":function($event){return _vm.selectItem(item)},"mouseleave":function($event){return _vm.unSelectItem()}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var props = ref.props;
return [_c('td',_vm._b({staticClass:"text-left"},'td',props,false),[_vm._v(" "+_vm._s(item.price))]),_c('td',_vm._b({staticClass:"text-right"},'td',props,false),[_vm._v(" "+_vm._s(item.quantity))])]}}],null,true),model:{value:(_vm.showSellTooltip[item.price]),callback:function ($$v) {_vm.$set(_vm.showSellTooltip, item.price, $$v)},expression:"showSellTooltip[item.price]"}},[(_vm.selectedItem !== null && _vm.selectedItem.price === item.price)?_c('div',[_c('div',[_vm._v("Average price: ~"+_vm._s(_vm.averagePrice))]),_c('div',[_vm._v("Sum "+_vm._s(_vm.pair.productCurrency)+": "+_vm._s(_vm.sumProduct))]),_c('div',[_vm._v("Sum "+_vm._s(_vm.pair.unitPriceCurrency)+": "+_vm._s(_vm.sumPaymentCurrency))])]):_vm._e()])],1)}),0)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }